<script>
export default {

  data() {
    return {
      content: this.$hscData.recipes,
      recipes: {},
      overlay: true,
    }
  },
  created() {
    let keys = Object.keys(this.$hscData.recipes).sort(x => .5 - Math.random()).slice(0, 6);
    keys.forEach(k => {
      this.recipes[k] = this.$hscData.recipes[k];
    })
  },
  mounted() {
    this.clicked.bind(this);
  },
  unmounted() {
  },
  methods: {
    clicked: (ev) => {
      ev.target.style.display = 'none';
      this.overlay = false;
    }
  },
}
</script>
<template>
  <section id="recipes">
    <div>
      <header>
        <h2 v-html="content.header"/>
        <p v-html="content.desc"/>
      </header>
      <div id="recipes-grid">
        <ul>
          <li v-for="(recipe, key) in recipes" :key="key">
            <router-link tag="a" active-class="active" :to="'/recipes/' + key">
              <picture><img :src="'images/sizes/_50/'+recipe.image" alt=""></picture>
              <div class="overlay">
                <div>
                  <h3 v-html="recipe.label"/>
                  <a class="hsbtn" v-html="$hscData.content.recipes.view[$root.lang]"></a>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
<style lang="scss">
@import "@/_variables.scss";

section#recipes {
  padding-bottom: 0 !important;
  max-width: 1024px;
  margin: 6rem auto 4rem auto;

  &:after {
    display: none;
  }

  #recipes-grid {
    ul {
      display: grid;
      grid-gap: 0;
      justify-content: center;
      $gs: 28vw;
      grid-template-columns: $gs $gs $gs;
      //grid-template-rows: $gs $gs;

      $ss: 28rem;
      @media(min-width: 1480px) {
        grid-template-columns: $ss $ss $ss;
        //grid-template-rows: $ss $ss;
        margin-bottom: 7rem;
        li .overlay h3 {
          font-size: 2rem !important;
        }
      }
      @media(max-width: 720px) {
        box-sizing: border-box;
        padding: 1rem;
        grid-template-columns: auto;
        grid-template-rows: 15rem;
        grid-gap: 1rem;
        padding: 1rem;
        display: block;
        li + li {
          margin-top: 1rem;
        }
      }
      width: 100%;
      margin: 0 auto;
      justify-items: center;
      position: relative;

      li {
        position: relative;
        @media(max-width: 720px) {
          //min-height: 50vw !important;
          height: 15rem !important;
        }

        picture {
          padding: 0;
          margin: 0;
          display: block;
          width: 100%;
          height: 100%;
          position: relative;
          display: block;
          line-height: 0;
          overflow: hidden;


          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            transition: transform .5s;
            transform: scale(1.007);

          }
        }

        a:hover, a:focus {
          picture {
            img {
              transform: scale(1.05);
            }
          }

          .overlay {
            opacity: 1;

            > div {
              filter: blur(0);
              transform: translateY(0);
            }

          }
        }

        .overlay {
          backdrop-filter: blur(2px) brightness(.8);
          cursor: pointer;
          background-size: cover;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 3;
          opacity: 0;
          display: grid;
          transition: opacity .45s, filter .1s;

          justify-content: center;
          align-content: center;
          align-items: center;
          text-align: center;


          &:before {
            pointer-events: none;
            content: " ";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
            background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, .8) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, .8) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, .8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0); /* IE6-9 */
          }


          > div {
            transition: opacity .5s, filter .6s, transform .3s;
            transform: translateY(1.5rem);
            //filter: blur(2px);
            padding: 2rem 2rem 3rem;
            pointer-events: none;
            position: relative;
            z-index: 4;
            flex: 1;
            color: #ffffff;

            header {
              @extend .bpt
            }

            h3 {
              //font-size: clamp(1.4rem, 1vw, 2.3rem);
              font-size: clamp(1.4rem, 3vw, 2.3rem);
            }
          }

          @media(max-width: 920px) {
            > div {
              padding: 1rem !important;

              > a {
                padding: 1rem;
                margin-bottom: 1rem;
              }
            }
          }

          @media(max-width: 720px) {
            opacity: 1;
            > div {
              height: 100%;
              box-sizing: border-box;
              filter: blur(0) !important;;
              transform: translateY(0) !important;
              padding: 1rem 1rem 1rem !important;

              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              a {
                position: relative;
                clear: both;
                //position: absolute;
                //bottom: 0;
                //left: 50%;
                //transform: translateX(-50%);
              }

              h3 {
                font-size: clamp(1.4rem, 6vw, 2.3rem);
              }


            }
            &:before {
              pointer-events: none;
              content: " ";
              display: block;
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              z-index: 0;
              /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
              background: -moz-linear-gradient(top, rgba(0, 0, 0, .2) 0%, rgba(0, 0, 0, .7) 100%); /* FF3.6-15 */
              background: -webkit-linear-gradient(top, rgba(0, 0, 0, .2) 0%, rgba(0, 0, 0, .7) 100%); /* Chrome10-25,Safari5.1-6 */
              background: linear-gradient(to bottom, rgba(0, 0, 0, .2) 0%, rgba(0, 0, 0, .7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
              filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0); /* IE6-9 */
            }
          }
        }
      }
    }
  }
}

</style>
