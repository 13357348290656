import Vue from 'vue'
import VueRouter from 'vue-router'
import notfound from '@/views/notfound.vue'
import home from '@/views/home.vue'
import terms from '@/views/terms.vue'
import accessibility from '@/views/accessibility.vue'
// import faq from '@/views/faq.vue'
import sauce from '@/views/sauce.vue'
import recipe from '@/views/recipe.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/', component: home
    },
    {
        path: '/terms', component: terms,
        meta: {
            title: 'Privacy Policy + Terms of Use'
        }
    },
    {
        path: '/accessibility', component: accessibility,
        meta: {
            title: 'Accessibility'
        }
    },
    // {
    //     path: '/faq', component: faq,
    //     meta: {
    //         title: 'FAQ'
    //     }
    // },
    {path: '/sauces/:slug', component: sauce},
    {path: '/recipes/:slug', component: recipe},
    {path: '/:subsection', component: home},
    {path: '*', component: notfound}
];
const scrollBehavior = (to, from, savedPosition) => {
    if (to.hash) {
        let el = document.querySelector(to.hash);
        el.scrollIntoView();
        return {selector: to.hash};
    } else {
        return savedPosition || {x: 0, y: 0};
    }
};

let router = new VueRouter({
    mode: 'history',
    scrollBehavior,
    routes,
})

router.beforeEach((to, from, next) => {
    document.body.setAttribute('tabindex', '-1');
    document.body.focus();
    document.body.removeAttribute('tabindex');
    next();
});


export default router
