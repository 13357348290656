<template>
  <section class="recipe">
    <div class="photo">
      <transition name="fade">
        <div>
          <picture v-show="imageLoaded">
            <img @load="imageLoaded = true" :src="'/images/sizes/_50/' + recipe.image" width="100%" alt=""
                 :style="'transform: scale(' + (1 + ($root.scrollPosition/15000)) + ')'"
            >
          </picture>
        </div>
      </transition>
    </div>
    <div class="right">
      <transition name="fade">
        <div class="inner" v-show="imageLoaded">
          <h2 v-html="recipe.label"/>
          <div>Prep time: {{ recipe.preptime }} | Cooking time: {{ recipe.cookingtime }} | Serves {{
              recipe.serves
            }}
          </div>

          <p v-html="recipe.desc"></p>
          <div class="ingredients">
            <h4 v-html="$hscData.content.ingredients[$root.lang]"/>
            <ul>
              <li v-for="ing in recipe.ingredients">
                <header v-if="ing.length > 2">{{ ing }}</header>
                <div v-else><strong>{{ ing[0] }}</strong> <span>{{ ing[1] }}</span></div>
              </li>
            </ul>
          </div>
          <div class="instructions">
            <h4 v-html="$hscData.content.instructions[$root.lang]"/>
            <ul>
              <li v-for="(ins,index) in recipe.instructions">
                <strong>{{ index + 1 }}</strong> <span>{{ ins }}</span>
              </li>
            </ul>
          </div>
          <div class="tips">
            <h4>Tip{{ recipe.tip.length > 1 ? 's' : '' }}</h4>
            <ul>
              <li v-for="(tip,index) in recipe.tip">
                <span>{{ tip }}</span>
              </li>
            </ul>
          </div>
          <Sharer
              :message="`Check out this recipe: ${recipe.label}`"
              :URL="URL"
          />
          <div class="featured">
            <h3 v-html="$hscData.content.in_this_recipe[$root.lang]"/>
            <ul>
              <li v-for="item in recipe.featured" :key="item + '-featured'">
                <saucecard :item="$hscData.sauces[item]" :id="item"/>
              </li>
            </ul>
          </div>
        </div>
      </transition>
    </div>

  </section>
</template>
<script>
import saucecard from '@/components/saucecard';
import Sharer from '@/components/sharer';

export default {
  components: {
    Sharer,
    saucecard
  },
  data: function() {
    return {
      hscData: this.$hscData,
      recipe: {},
      imageLoaded: false,
      URL: window.location.href
    };
  },
  computed: {
    slug: function () {
      return this.$route.params.slug;
    },
  },
  created() {
    this.recipe = this.hscData.recipes[this.slug];
  },
  mounted() {
    document.body.scrollTo(0, 0);
    if (window.Sharer) window.Sharer.init();

  },
  unmounted() {
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
@import "@/_variables.scss";

section.recipe {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  //background-color: antiquewhite;
  //background: url('../images/sizes/_50/HP-Background.png'), url('../images/sizes/_15/HP-Background.png');
  background-size: cover;

  > div {
    min-height: 100vh;

    h4 {
      color: #543e33;
      font-size: 1.3rem;
      text-align: left;
      font-family: 'GothamBold', 'Gotham Book', Helvetica, Arial, sans-serif;
    }

    flex: 1 40%;

    &.photo {
      picture {
        display: block;

        img {
          display: block;
        }
      }

      > div {
        //overflow: hidden;
        top: 0;
        position: sticky;

        img {
          object-fit: cover;
          height: 100vh;
          width: 100%;
        }
      }
    }

    &.right {
      background: #ffffff;
      padding: 6rem 8rem;

      .inner {
        text-align: center;
        margin: 0 auto;
      }
    }
  }

  .ingredients {
    h4 {
      margin-top: 0rem;
      margin-bottom: 2rem;
    }

    strong {
      font-family: 'GothamBold', 'Gotham Book', Helvetica, Arial, sans-serif;
      color: #543e33;

    }

    li + li {
      margin-top: .4rem;
    }

    text-align: left;
    background: #f8f6f1;
    padding: 2rem;
  }

  .instructions {
    h4 {
      margin-top: 0rem;
      margin-bottom: 2rem;
    }

    li {
      min-height: 2rem;
      display: block;
      position: relative;
      padding-left: 1.7rem;;

      + li {
        margin-top: 1.3rem;
      }

      strong {
        @extend .bpt;
        color: #543e33;
        position: absolute;
        left: 0;
        margin-top: -.25rem;
        font-size: 2rem;
      }
    }

    text-align: left;
    padding: 2rem;


  }

  .tips {
    //max-width: 60%;
    margin: 0 auto;

    h4 {
      margin-top: 0rem;
      margin-bottom: 2rem;
      text-align: center;
    }

    li {
      margin-bottom: 2rem;
    }
  }

  .featured {
    padding-top: 0;

    li + li {
      margin-left: 1rem;
    }

    .saucecard {
      img {
        width: 10rem;
      }
    }
  }

  @media (max-width: 720px) {
    display: block;
    > div {
      flex: none;
      min-height: 0;

      &.photo {
        height: 60vw;

        > div {
          height: 100%;
        }

        picture {
          display: block;
          width: 100%;
          height: 100%;

          img {
            position: relative;
            object-fit: cover;
            width: 100%;
            height: 100%;
            object-position: center 80%;;
          }
        }

      }

      &.right {
        z-index: 2;
        position: relative;
        padding: 3rem 2rem!important;

        .inner {

        }
      }
    }

  }


}

</style>
