<script>
import saucecard from '@/components/saucecard';

export default {
  name: 'hscmenu',
  components: {
    saucecard
  },
  data() {
    return {
      hscData: this.$hscData,
      menu: false,
    }
  },
  mounted() {
  },
  methods: {
    toggle: function () {
      this.menu = !this.menu
    },
  },
  watch: {
    '$route'() {
      this.menu = false;
    }
  }
}
</script>
<template>
  <nav id="hscmenu" :class="menu ? 'showing' : ''">
    <div class="menu-button" @click='toggle()' tabindex="0">
<!--      <div v-show="!menu"><span class="material-icons">menu</span></div>-->
<!--      <div v-show="menu"><span class="material-icons">close</span></div>-->
      <div id="nav-icon3">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <transition name="slide-fade">

      <div id="menu" v-show="menu">

        <div class="inside">

          <div class="hlf">
            <div class="logo">
              <router-link tag="a" to="/">
                <img src="/logo.png" alt="">
              </router-link>
            </div>
            <ul>
              <li v-for="(item, key) in hscData.nav" :id="key + '-link'">
                <router-link
                    tag="a" class="col" active-class="active"
                    :to="{ path: '/', hash: key }">
                  {{ item.label }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="our-sauces-panel">
            <ul>
              <li v-for="item in hscData.sauces" :key="item.slug">
                <saucecard :item="item" :id="item.slug"/>
              </li>
            </ul>
          </div>
        </div>
        <div id="mobile-links">
          <ul>
            <li id="wheretobuy-link-mobile"><a href="/#wheretobuy" class="col"> Where To Buy </a></li>
          </ul>
        </div>
      </div>
    </transition>
  </nav>
</template>
<style lang="scss">
@import "@/_variables.scss";

#hscmenu {
  color: #222222;
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  pointer-events: none;
  @media (max-width: 920px) {
    //bottom: 0;
  }

  &:before {
    background: #000000;
    content: " ";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    transition: opacity .5s;
  }

  &.showing {
    &:before {
      opacity: .3;
    }
  }

  #menu {
    position: relative;
    z-index: 4;
    pointer-events: all;
    background-color: #f8f6f1;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    @media(min-width: 921px) {
      //height: 55vh;
      //min-height: 32rem;
      height:32rem;
    }

    .logo {
      padding-bottom: 3rem;

      img {
        max-height: 3rem;
        margin: 0 auto;
        display: block;
      }
    }

    .saucecard {
      div.copy {
        div {
          font-size: 1.2rem;
          color: #543e33aa !important;

        }

      }

      &.active, &:hover {
        div.copy {
          div {
            color: #543e33 !important;

          }
        }
      }
    }

    .inside {
      height: 100%;
      display: flex;
      flex-direction: row-reverse;
      align-content: center;
      align-items: center;
    }

    #mobile-links {
      display: none;
    }


    a {
      text-decoration: none;
      color: #543e33;
    }

    .inside {
      > * {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .hlf {
        background-color: #ffffff;
        flex: 1 28%;
        padding: 2rem;
        position: relative;
        box-sizing: border-box;

        ul {
          display: flex;
          flex-direction: column;

          li {
            text-align: left;
            + li {
              margin-top: 2rem;
            }
          }
        }

        @media(max-width: 920px) {

          position: relative;
          width: 100%;
          //padding-bottom: 3rem;
          li {
            text-align: center !important;
          }
        }
      }

      .logo {
        padding-bottom: 3rem;
        position: absolute;
        top: 1rem;
        right: 0;
        left: 0;
        @media(max-width: 920px) {
          right: 0;
          left: 0;
          position: relative;
          padding-bottom:2rem;
        }
      }

      > .our-sauces-panel {
        flex: 1 80%;

        ul {
          padding: 0;
          width: 97%;
          max-width: 920px;
          display: flex;
          margin: 0 auto;

          li {
            padding: 0;

            flex: 1;
            display: flex;
            justify-content: center;

          }
        }

        @media(max-width: 920px) {
          div.copy div {
            color: $hsc-brown !important;
          }
        }
      }
    }

    .inside .hlf {
      ul {
        width: 14rem;
        margin: 0 auto;

        a {
          font-family: GothamBold, "Arial Black", sans-serif;
          font-weight: 600;
          font-size: clamp(1.2rem, 2vw, 1.3rem);
          text-rendering: geometricPrecision;
          -webkit-font-smoothing: subpixel-antialiased;
          &.active:after {
            content: ' ';
            width: 1rem;
            border-top: 2px solid $hsc-brown;
            margin-left: .5rem;
            height: .5rem;
            display: inline-block;
          }
        }
      }
    }

    @media (max-width: 920px) {
      bottom: 0;
      position: absolute;
      height: 100vh;
      flex-direction: column;

      .inside {
        height: 100vh;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;

        .hlf ul {
          //flex-direction: row;
          margin: 1vh auto;
          flex-wrap: wrap;
          align-content: center;
          display: block;

          li {
            font-size: 2rem;
            text-align: center;
            flex: 30%;

            + li {
              margin-top: .5vh;
            }

            &:last-child {
              flex: 100%;
            }

            a {
              font-size: MIN(12vw, 1.4rem);
              margin: 0 auto;
            }

            border-bottom: 2px solid transparent;

            a.active {
              &:after {
                content: " ";
                display: none;
              }

              border-bottom: 2px solid $hsc-brown;
            }
          }
        }
      }
      #wheretobuy-link {
        //display: none !important;
      }


      .inside > .our-sauces-panel {
        width: 100%;
        flex: 1 100%;

        justify-content: center;
        align-items: center;
        justify-content: center;

        > ul {
          width: 80%;
          display: grid;
          grid-template-columns: 50% 50%;
          grid-template-rows: 18vh;
          grid-gap: 3vh;
          justify-content: center;
          justify-items: center;
          margin: 1rem 0 2rem;

          li {
            flex: 100%;
            text-align: center;

            + li {
              margin-top: 0;
            }

            a {
              display: inline-block;

              div {
                font-size: MIN(4vw, 1.1rem) !important;

              }

              img {
                //display: none;
                width: 8vh;
              }
            }
          }
        }
      }
    }

  }

  .menu-button {
    pointer-events: all;
    padding-top: 0.3rem;
    &:focus {
      outline: none;
    }

    font-size: clamp(2rem, 3vw, 2.6rem);
    margin: 0.5em;
    cursor: pointer;
    position: fixed;
    background: transparent;
    //padding: 0.25em;
    //width: 0.7em;
    //height: 0.7em;
    cursor: pointer;
    position: fixed;
    //background: #6d4b39;
    right: 0;
    z-index: 2222;
    backdrop-filter: blur(6px);
    border-radius: 50%;

    .material-icons {
      font-size: .8em;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
    }
  }
}



#nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {
  width: 2.3rem;
  height: 1.9rem;
  position: relative;
  //margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background:#8a8985;
  border-radius: 9px;
  opacity: .8;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .15s ease-in-out;
  -moz-transition: .15s ease-in-out;
  -o-transition: .15s ease-in-out;
  transition: .15s ease-in-out;
}
/* Icon 3 */

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
  top: .8rem;
}
#nav-icon3 span:nth-child(3) {
  opacity:0;
}

#nav-icon3 span:nth-child(4) {
  top: 1.6rem;
}


.showing #nav-icon3 span:nth-child(3) {
  opacity:.8;
}
.showing #nav-icon3 span:nth-child(1) {
  top: .7rem;
  width: 0%;
  left: 50%;
}

.showing #nav-icon3 span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.showing #nav-icon3 span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.showing #nav-icon3 span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

</style>
