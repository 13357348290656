<template>
  <section id="terms">
    <div>
      <header>
        <h2 v-html="page.title"/>
      </header>
      <div v-html="page.content"/>
    </div>
  </section>
</template>
<script>
export default {
  data: function() {
    return {
      content: this.$hscData.content.recipes,
      hscData: this.$hscData,
      page: this.$hscData.pages.terms
    }
  },
  created() {
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
@import "@/_variables.scss";

section#terms {
  max-width: 1024px;
  margin: 0 auto;

  strong {
    font-family: "BrandonPrinted-Two";
    font-weight: 700;
  }

  h2 {
    padding: 2rem;

    span {
      display: block;
    }
  }

  padding: 3rem 0;

  div#terms {
    padding: 2rem;

  }
}
</style>
