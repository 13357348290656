<template>
  <div class="social">
    <h3 v-html="$hscData.content.sharethis[$root.lang]"/>

    <a tabindex="0" data-sharer="facebook" data-width="800" data-height="600"
       :data-title="message" :data-url="URL"><i
        class="fa fa-facebook fa-lg"></i></a>
    <a tabindex="0" data-sharer="twitter" data-width="800" data-height="600"
       :data-title="message" :data-url="URL"><i
        class="fa fa-twitter fa-lg"></i></a>
    <a tabindex="0" data-sharer="email" data-width="800" data-height="600"
       :data-title="message" :data-url="URL"><i
        class="fa fa-envelope fa-lg"></i></a>
  </div>
</template>
<script>
export default {
  name: 'Sharer',
  props: ['message', 'URL']
}
</script>
<style lang="scss">
.social {
  text-align: center;
  margin: 2rem auto 4rem;

  h3 {
    font-size: clamp(1.2rem, 2.2vw, 1.5rem) !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  @media (max-width: 720px) {
    text-align: center;
    margin: 4rem auto;
  }

  a {
    font-size: 2rem;
    color: #543e33;
    display: inline-block;
    width: 3rem;
    height: 3rem;
    box-sizing: border-box;
    border: 2px solid #543e33;
    border-radius: 2rem;
    text-align: center;

    i {
      padding: 0;
      font-size: 20px;
      color: #543e33;
    }

    + a {
      margin-left: 1rem;
    }
  }
}

</style>