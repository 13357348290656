<template>
  <section class="sauce">
    <div class="photo">
      <div class="bott" :style="`background-color: ${sauce.bgcolor}`"></div>

      <transition name="fade">
        <div class="lefthold" v-show="imageLoaded">

          <div class="copy">
            <h2 v-html="sauce.label" :style="`background-color: ${sauce.bgcolor}`"/>
            <!--            <div class="dek" v-html="sauce.dek"/>-->
            <!--            <p v-html="sauce.longdesc"></p>-->
          </div>
          <picture>
            <img @load="imageLoaded = true" :src="'/images/sizes/_30/' + sauce.image" width="100%" alt=""
                 :style="'transform: scale(' + (1 + ($root.scrollPosition/30000)) + ')'">
          </picture>
          <div class="buynow for-desktop"><a target="_blank" class='hsbtn dark'
                                             :href="sauce.buyURL">BUY NOW</a></div>

          <Sharer
              class="for-desktop"
              :message="`Check out this sauce: ${sauce.label}`"
              :URL="URL"
          />
        </div>
      </transition>
    </div>
    <div class="right">
      <transition name="fade">
        <div v-show="imageLoaded">
          <div class="inner" v-show="imageLoaded">
            <h2 v-html="sauce.label" :style="`color: ${sauce.bgcolor}`"/>
            <div class="dek" v-html="sauce.dek"/>
            <p v-html="sauce.longdesc"></p>
            <div class="buynow for-mobile"><a target="_blank" class="hsbtn dark"
                                              :href="sauce.buyURL">BUY NOW</a></div>
            <div class="features">
              <ul>
                <li v-for="f in sauce.features">
                  <img :src="'/images/features/' + f + '.png'" width="100%" :alt="hscData.content.features[f]">
                </li>
              </ul>
            </div>
            <div class="nutritional_information">
              <h4>Nutritional Information</h4>
              <div class="servings">
                Serving size 1/4 cup (65g)<br>
                Servings per container 3.5
              </div>
              <ul>
                <li class="head"><strong>Amount per serving</strong><span></span><span>% DV</span></li>
                <li v-for="ing in sauce.aps">
                  <div :class="'indent'+ing[0]"><strong>{{ ing[1] }}</strong> <span>{{ ing[2] }}</span><span
                      v-if="ing.length > 3">{{ ing[3] }}</span></div>
                </li>
              </ul>
              <ul>
                <li v-for="ing in sauce.vm">
                  <div><strong>{{ ing[0] }}</strong> <span>{{ ing[1] }}</span><span
                      v-if="ing.length > 2">{{ ing[2] }}</span></div>
                </li>
              </ul>
            </div>
            <div class="ingredients">
              <h4>Ingredients</h4>
              <ul>
                <li v-for="(ing,index) in sauce.ingredients">
                  <span>{{ ing }}</span>
                </li>
              </ul>
            </div>


          </div>
          <div class="video" v-if="false">
            <img src="/images/_videoplaceholder.jpg" alt="" width="100%">
          </div>
          <Sharer
              class="for-mobile"
              :message="`Check out this sauce: ${sauce.label}`"
              :URL="URL"
          />

          <div class="featured inner">
            <h3 v-html="$hscData.content.bestsellers[$root.lang]"/>
            <ul>
              <li v-for="(item,key) in bestsellers" :key="key + '-bestseller'">
                <saucecard :item="item" :id="key" type="true"/>
              </li>
            </ul>
            <br>
            <router-link tag="a" class="hsbtn dark" active-class="active" :to="{ path: '/', hash: 'our-sauces' }">VIEW
              ALL SAUCES
            </router-link>
          </div>

        </div>
      </transition>
    </div>

  </section>
</template>
<script>
import saucecard from '@/components/saucecard.vue'
import Sharer from '@/components/sharer';

export default {
  name: 'sauce',
  components: {
    Sharer,
    saucecard
  },
  data: function () {
    return {
      hscData: this.$hscData,
      sauce: {},
      bestsellers: {},
      imageLoaded: false,
      URL: window.location.href

    };
  },
  computed: {
    slug: function () {
      return this.$route.params.slug;
    },

  },
  created() {
    this.sauce = this.hscData.sauces[this.slug];
    let keys = Object.keys(this.hscData.sauces).filter((k) => k != this.slug).sort(x => .5 - Math.random()).slice(0, 2);
    keys.forEach(k => {
      this.bestsellers[k] = this.hscData.sauces[k];
    })
  },
  mounted() {
    document.body.scrollTo(0, 0);
    if (window.Sharer) window.Sharer.init();

  },
  methods: {}
}
</script>
<style lang="scss" scoped>
@import "@/_variables.scss";

section.sauce {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  //background-color: antiquewhite;
  background: url('/images/sizes/_50/HP-Background.png'), url('/images/sizes/_15/HP-Background.png');
  background-size: cover;
  background-attachment: fixed;
  color: $hsc-brown;


  > div {
    min-height: 100vh;

    h4 {
      color: #543e33;
      font-size: 1.3rem;
      text-align: left;
      font-family: 'GothamBold', 'Gotham Book', Helvetica, Arial, sans-serif;
    }


    flex: 1 40%;

    &.photo {
      .lefthold {
        position: sticky;
        top: 0rem;
        margin-bottom: 2rem;

        .copy {
          h2 {
            text-align: center;
            padding: 2rem;
            color: #ffffff;
          }

          @media(max-width: 960px) {
            display: none;
          }
        }
      }

      //padding-bottom: 4rem;

      picture {
      }

      img {

        //object-fit: cover;
        //height: 50vh;
        width: 30vmin;
        display: block;
        margin: 3rem auto 3rem;

      }
    }

    &.right {
      background: #ffffff;

      .dek {
        font-size: 1.2rem;
        font-family: 'GothamBold', 'Gotham Book', Helvetica, Arial, sans-serif;
      }

      h2 {
        display: none;
        @media(max-width: 960px) {
          display: block;
          font-size: MIN(2.4rem, 4.8vw);
        }
        position: sticky;
        top: 0rem;
        background: #ffffffcc;
        backdrop-filter: blur(3px);
        padding: 1rem 2rem;
        margin: 0 -2rem;
        z-index: 22;
      }

      .inner {
        text-align: left;
        @media(max-width: 960px) {
          text-align: center;
        }
        margin: 0 auto;
        max-width: 540px;
        padding: 4rem 6rem;

      }

      .featured.inner {
        text-align: center;
        padding-top: 0;

        li + li {
          margin-left: 1rem;
        }

        li {
          display: inline-block;
          width: 47%;
          vertical-align: top;
        }
      }
    }
  }

  .features {
    ul {
      margin: 4rem 1rem;
      display: grid;
      grid-template-columns:30% 30% 30%;
      justify-content: center;
      align-items: flex-start;
      justify-items: center;
      align-content: center;
      grid-gap: 1.5rem;

      li {
        display: table;
        //width: 128px;
        //height: 128px;
        //margin: 2rem 0;
      }
    }

    img {
      vertical-align: top;
      display: inline-block;
      max-width: 128px;
    }
  }

  .nutritional_information {
    box-sizing: border-box;
    width: 100%;

    h4 {
      margin-top: 0rem;
      margin-bottom: .5rem;
    }

    .servings {
      margin-bottom: 2rem;
    }

    strong {
      font-family: 'GothamBold', 'Gotham Book', Helvetica, Arial, sans-serif;
      color: #543e33;

    }

    ul {


      li {
        &.head {
          margin-bottom: .8rem;
          padding-bottom: .8rem;
          border-bottom: 1px solid #edeae4;
        }

        span:last-child {
          float: right;
          font-family: 'GothamBold', 'Gotham Book', Helvetica, Arial, sans-serif;

        }
      }
    }

    ul + ul {
      border-top: 1px solid #edeae4;
      margin-top: 1rem;
      padding-top: 1rem;

    }

    li + li {
      margin-top: .4rem;
    }

    text-align: left;
    background: #f8f6f1;
    padding: 2rem;
  }

  .ingredients {
    h4 {
      margin-top: 0rem;
      margin-bottom: 2rem;
    }

    li {
      display: block;
      position: relative;

      + li {
        margin-top: .5rem;
      }

      strong {
        @extend .bpt;
        color: #543e33;
        position: absolute;
        left: 0;
        margin-top: -.25rem;
        font-size: 2rem;
      }
    }

    text-align: left;
    @media(max-width: 640px) {
      text-align: center;
      h4 {
        text-align: center;

      }
    }
    padding: 2rem 0;


  }

  .tips {
    max-width: 60%;
    margin: 0 auto;

    h4 {
      margin-top: 0rem;
      margin-bottom: 2rem;
      text-align: left;
    }

    li {
      margin-bottom: 2rem;
    }
  }

  @media (max-width: 960px) {
    display: block;
    > div {
      flex: none;
      min-height: 0;

      &.photo {
        height: 65vw;
        box-sizing: border-box;

        > div {
          height: 90%;
          box-sizing: border-box;

        }

        picture {

          display: block;
          width: 100%;
          height: 100%;
          padding: 1rem;
          box-sizing: border-box;

          img {
            //box-sizing: border-box;
            //position: relative;
            //object-fit: contain;
            //width: 100%;
            //height: 100%;
            //object-position: center 80%;;
            //margin: 1rem auto;
            //top: auto;

            box-sizing: border-box;
            position: relative;
            /* object-fit: contain; */
            width: auto;
            height: 100%;
            object-position: center 80%;
            margin: 1rem auto;
            top: auto;
            position: relative;
            z-index: 4;

          }


        }

        position: relative;

        picture {
          position: relative;
          width: auto;
          height: 58vw;

        }

        .bott {
          content: " ";
          //border-top:10rem solid;
          height: 100%;
          display: block;
          position: absolute;
          //height: 3rem;
          //background: #ffffff;
          width: 100%;
          bottom: 0;
          left: 0;
          right: 0;
          mix-blend-mode: multiply;
        }
      }

      &.right {
        z-index: 2;
        position: relative;
        padding: MIN(7vw, 4rem) !important;

        .inner {
          padding: 0;
        }
      }
    }

  }

  .buynow {
    text-align: center;

    @media(max-width: 960px) {
      margin-top: 1rem;
      margin-bottom: 2rem;
      a {
        margin-top: 0rem;
      }
    }

    a {
      margin-top: 1rem;
      background: $hsc-brown;
      color: #fff;
      padding: 1rem 2.2rem;
      text-decoration: none;
      font-family: 'BrandonPrinted-Two', "Arial Black", sans-serif;
      font-size: 1.4rem;
    }
  }
}

.promo {
  background-color: rgb(251, 237, 180);
  text-align: center;
  padding: 1rem 0;
  margin: 0rem auto;
  max-width: 640px;
  width: 90%;

}

.promo p {
  margin: 0 auto;
  max-width: 640px;
  width: 80%;
  font-size: 0.9rem;
  line-height: 1.2rem;
  strong {
    font-family: 'BrandonPrinted-Two', "Arial Black", sans-serif;
  }
}

body {
  position: relative;
}

</style>
