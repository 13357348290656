<script>
export default {
  data() {
    return {
      hscData: this.$hscData,
      content: this.$hscData.content.howtouse,
      hasYT: hasYT,
      YT: window.YT,
      YTcheck: null,
      overlay: true,
      YTplayers: [],
      lastPlayer: null
    }
  },
  mounted() {
    this.handleYT.bind(this);
    this.startVideo.bind(this);
    this.onPlayerStateChange.bind(this);

    this.YTcheck = setInterval(() => {
      if (window.YT && window.YT.loaded) {
        this.hasYT = true;
        clearInterval(this.YTcheck)
        this.handleYT();
      }
    }, 100)
  },
  unmounted() {
  },
  methods: {
    startVideo: function (ev) {
      let video = ev.target.dataset.video;
      this.YTplayers[video].playVideo();
      let container = ev.target.getIframe().parentNode
      if (ev.data === 1) { // playing
        container.classList.add('playing');
        if (this.lastPlayer !== null && ev.target !== this.lastPlayer) {
          this.lastPlayer.stopVideo();
        }
        this.lastPlayer = ev.target;
      } else { // something else
        container.classList.remove('playing');
      }
    },
    handleYT: function () {
      let items = [
        ['video1', this.hscData.content.videos.video1.ID],
        ['video2', this.hscData.content.videos.video2.ID],
      ];
      items.forEach(o => {
        this.YTplayers[o[0]] = new window.YT.Player('ytplayer-' + o[0], {
          height: '390',
          width: '640',
          videoId: o[1],
          playerVars: {rel: 0, showinfo: 0, ecver: 2, modestbranding: 1},
          events: {
            'onStateChange': this.onPlayerStateChange
          }
        });
      });
    },
  }
}
</script>
<template>
  <section id="howtouse">
    <div>
      <header>
        <h2 v-html="content.header[$root.lang]"/>
        <p v-html="content.body[$root.lang]"/>
      </header>
      <div class="videos">
        <div v-for="(video, key) in hscData.content.videos" :key="key" :class="'video-row '+key">
          <div class="video">
            <div class="overlay" :style="{'background-image':'url('+video.poster+')'}"
                 v-show="overlay" :data-video="key" @click="startVideo">
              <div>
                <img src="/images/play.svg" alt="">
                <h3 v-html="video.title.en"/>
                <p v-html="video.desc.en"/>
              </div>
            </div>
            <div :id="'ytplayer-' + key" class="video-container"></div>
          </div>
          <div v-if="key == 'video1'" class="benefits">
            <div><img src="/images/benefits/1.png" alt=""></div>
            <div><img src="/images/benefits/2.png" alt=""></div>
            <div><img src="/images/benefits/3.png" alt=""></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style lang="scss">
@import "@/_variables.scss";

#howtouse {
  $dockbp: 1600px;
  overflow-x: hidden;


  .benefits {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
    text-align: center;
    @media(min-width: 641px) {

    }

    //margin-top: 1rem;
    //padding: 1rem 0;

    div {
      flex: 0 auto;

      img {
        width: 10vw;
        max-width: 8rem;
      }

      margin: .6rem 1rem;
    }

    @media(max-width: 640px) {
      flex-direction: row;
      div {
        img {
          width: 22vw;
        }

        margin: 4vw;
      }
    }
  }

  .videos {
    max-width: 1782px;
    margin: 0 auto;

    .video-row {
      position: relative;


      &.video1 {
        //margin-right: 2rem;
        text-align: right;
        @media (min-width: 641px) {
          display: flex;
          flex-direction: row-reverse;
        }

        .video {
          @media (min-width: $dockbp - 600) {
            margin: 0 9vw 0 0;
          }
        }

        //
        //&:before {
        //  pointer-events: none;
        //  content: " ";
        //  display: block;
        //  background: url(/images/benefits.png) no-repeat center bottom;
        //  background-size: max(7rem, 5vw);
        //  position: absolute;
        //  width: 20vw;
        //  max-width: 40rem;
        //  height: 98%;
        //  left: 6vw;
        //  z-index: 5;
        //  top: 0;
        //}
      }

      &.video2 {
        //margin-left: 2rem;
        margin: 6rem 0 0;
        @media(max-width: 640px) {
          margin-top: 0rem;
        }

        .video {
          @media (min-width: $dockbp - 600) {
            margin: 0 22vw 0 auto;
          }

        }

        &:after {
          pointer-events: none;
          content: " ";
          display: block;
          background: url(~@/../public/images/sizes/_30/HP-Background-Lemon.png);
          background-size: cover;
          position: absolute;
          width: 30vw;
          height: 30vw;
          max-width: 40rem;
          max-height: 40rem;

          right: -20px;
          z-index: 5;
          left: auto;
          top: -2rem;
        }
      }
    }
  }

  .video-row {
    @media(max-width: 640px) {
      text-align: center !important;
      &:after, &:before {
        display: none !important;
      }
      .overlay {
        //align-items: flex-end !important;
        padding-top: 3rem;

        h3 {
          width: 80%;
          margin: 1rem auto 1rem auto;
        }

        p {
          display: none;
        }
      }
    }

  }

  .video {
    cursor: pointer;

    position: relative;
    display: inline-block;
    @media (min-width: 1280px) {
      display: block;
    }
    @media (min-width: $dockbp) {
      //margin: 0 auto  !important;
    }


    &, .video-container, iframe {
      width: 76vw;
      height: 42.8vw;
      max-width: 990px * 1.2;
      max-height: 557px * 1.2;
      @media(max-width: 640px) {
        width: 93vw;
        height: 52vw;
        min-height: 14rem;
      }
    }

    cursor: pointer;

    .overlay {

      cursor: pointer;
      background-size: cover;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 3;
      display: flex;
      transition: opacity .5s;

      &:before {
        cursor: pointer;

        pointer-events: none;
        content: " ";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.81) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.81) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.81) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0); /* IE6-9 */
      }

      justify-content: center;
      align-content: center;
      align-items: center;
      text-align: center;

      > div {

        pointer-events: none;
        position: relative;
        z-index: 4;
        flex: 1;
        color: #ffffff;

        h3 {
          @extend .bpt
        }

        p {
          font-size: clamp(1.2rem, 1.2vw, 2rem)
        }

        img {
          width: clamp(3rem, 10vw, 10rem)
        }
      }


    }

    &.playing {
      .overlay {
        opacity: 0;
        pointer-events: none;
      }
    }

    @media(max-width: 640px) {
      //display: flex;
      //flex-direction: column-reverse;
      //.overlay {
      //  opacity:1 !important;
      //  background:transparent !important;
      //  position: relative;
      //  div {
      //    color: $hsc-brown !important;
      //
      //  }
      //  &:before {
      //    display: none;
      //  }
      //}
    }
  }
}

</style>
