<template>
  <section id="notfound">
    <header>

      <h2>Oops!</h2>
      <p>Sorry, we were unable to find what you were looking for. Visit the <a href="/">homepage</a> or explore our
        sauces below.</p>
    </header>
    <oursauces/>

  </section>
</template>
<script>

import Oursauces from '@/components/oursauces';

export default {
  name: 'notfound',
  components: {Oursauces},
  data: function() {
    return {};
  },
  created() {
  },
  methods: {}
}
</script>
<style lang="scss">
section#notfound {
  padding-top: 4rem;

  h2 {
    font-size: 3rem;
  }

  > header {
    > p {
      max-width: 600px;
      font-size: 1.2rem;
      text-align: center;
    }

  }

  #our-sauces {
    padding-top: 1rem;

    header {
      display: none;
    }
  }

  display: grid;
  align-content: center;
  align-items: center;
  justify-items: center;
  justify-content: center;
}
</style>
