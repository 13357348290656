<template>
    <div class="wrapper">
      <hero/>
      <oursauces/>
      <howtouse/>
      <recipes/>
      <wheretobuy/>
    </div>
</template>
<script>
import hscmenu from '@/components/hscmenu.vue'
import hero from '@/components/hero.vue'
import oursauces from '@/components/oursauces.vue'
import howtouse from '@/components/howtouse.vue'
import recipes from '@/components/recipes.vue'
import wheretobuy from '@/components/wheretobuy.vue'

export default {
  name: 'home',
  components: {
    hscmenu,
    hero,
    oursauces,
    howtouse,
    recipes,
    wheretobuy
  },
  data: function() {
    return {}
  },
  created() {
  },
  methods: {}
}
</script>
<style lang="scss" scoped>

</style>
