<template>
  <main>
    <a tabindex="0" id="contentskip" href="#content">Skip to Content</a>
    <hscmenu/>
    <div id="content" :class="loaded ? 'loaded' : 'notloaded'">
      <router-view :key="$route.fullPath"/>
    </div>
    <bottom></bottom>
  </main>
</template>

<script>
import hscmenu from '@/components/hscmenu'
import bottom from '@/components/bottom'
import home from '@/views/home.vue'
import sauce from '@/views/sauce.vue'
import recipe from '@/views/recipe.vue'


export default {
  components: {
    hscmenu,
    bottom,
  },
  data() {
    return {
      hscData: this.$hscData,
      desc: document.querySelector('meta[name="description"]'),
      loaded: false
      // isLoading: true,
      // fullPage: false,
      // loader: 'bars'
    }
  },
  watch: {
    '$route': {
      handler: function (to, from) {
        let shareImage = '/images/share.jpg'
        let documentTitle = document.title
        let description = 'Our sauces bring more flavor and juiciness to rotisserie chicken for a gourmet taste without the stress! Just shred or section, pour the sauce, heat it up, and dinner is done.'

        if (to.fullPath.indexOf('/sauces/') === 0) {
          let slug = to.params.slug
          let sauce = this.hscData.sauces[slug]
          documentTitle = sauce.label + ' | Rotisserie Sauce | Homestead Chef\'s Table'
          shareImage = '/images/share/share_' + slug + '.jpg'
          this.desc.content = sauce.desc
          description = sauce.desc
        } else if (to.fullPath.indexOf('/recipes/') === 0) {
          let slug = to.params.slug
          let recipe = this.hscData.recipes[slug]
          documentTitle = recipe.label + ' | Recipes | Homestead Chef\'s Table'
          this.desc.content = recipe.desc
          description = recipe.desc
        } else {
          documentTitle = (to.meta.title ? to.meta.title + ' | Homestead Chef\'s Table' : 'Homestead Chef\'s Table')
        }

        // set title
        document.querySelector('meta[property="og:title"]').setAttribute('content', documentTitle)
        document.querySelector('meta[name="twitter:title"]').setAttribute('content', documentTitle)
        document.title = documentTitle

        // set description
        document.querySelector('meta[property="og:description"]').setAttribute('content', description)
        document.querySelector('meta[name="twitter:description"]').setAttribute('content', description)
        document.querySelector('meta[name="description"]').setAttribute('content', description)

        // set share image
        document.querySelector('meta[property="og:image"]').setAttribute('content', shareImage)
        document.querySelector('meta[name="twitter:image"]').setAttribute('content', shareImage)
        // setTimeout(()=>{
        //   document.activeElement.blur()
        // },100)

      },
      immediate: true
    },
  },
  created() {
    let items = document.querySelectorAll('img'), itemslen = items.length;
    let _self = this;
    items.forEach(o => {
      if (o.width) {
        itemslen--
      } else {
        o.addEventListener('load', evt => {
          itemslen--
          if (itemslen == 0) {
            _self.loaded = true
          }
        })
      }
    })
    if (itemslen == 0) {
      this.loaded = true
    }
  }
}

</script>
<style lang="scss">
@import "@/_variables.scss";

html {
  //overflow: hidden;
  height: 100%;
  will-change: scroll-position;
}

body {
  height: 100%;
  //overflow: auto;
  background: #333333;

}

body {
  #content {
    opacity: 0;
    background: #333333;

    &.loaded {
      opacity: 1;
      //border: 4px solid red;
    }
  }
}

#contentskip {
  z-index: 1;
  background: #FFFFFF;
  position: absolute;
  left: -10000px;
  width: 100%;
  font-size: 2rem;
  padding: 1rem;
  text-align: center;

  &:focus {
    left: 0;
  }
}

[v-cloak] {
  display: none;
}

.navbar {
  margin-bottom: 20px;
}

/*.body-content {
    padding-left: 25px;
    padding-right: 25px;
}*/
html {
  @media (min-width: 1280px) {
    font-size: 1.2vw;
  }
  @media (min-width: 1367px) {
    font-size: 16px;
  }
}

body {
  padding: 0;
  margin: 0;

}

input,
select {
  max-width: 30em;
}

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active below version 2.1.8 */

{
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in .5s;
}

.bounce-leave-active {
  animation: bounce-in .5s reverse;
}

.bpt {
  font-family: 'BrandonPrinted-Two';
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

div#content {

  background-image: url("/images/sizes/_30/HP-Background.jpg");
  background-size: contain;

}

h2 {
  @extend .bpt;
  margin: 0 0 1rem;
  font-size: clamp(1.6rem, 2.9vw, 1.8rem);
  font-weight: normal;
  color: #543e33;
  text-align: center;
}

h3 {
  @extend .bpt;
  font-size: clamp(1.2rem, 2.2vw, 2.2rem);

}

p {
  line-height: 1.5em;

}

main {
  //overflow: hidden;
  color: #543e33;
  background-color: #f8f6f1;

  a {
    color: #543e33;
  }

  .social > a {
    cursor: pointer;
  }

  > div {
    min-height: 100vh;
    //section:before {
    //  content:"";
    //  display:block;
    //  height:3rem; /* fixed header height*/
    //  margin:-3rem 0 0; /* negative fixed header height */
    //}
    section + section {
      padding-top: 4rem;
      @media(max-width: 640px) {
        padding-top: 0rem;
        &:after {
          content: " ";
          width: 4rem;
          margin: 2rem auto;
          height: .2rem;
          display: block;
          opacity: .3;
          background: #543e33;
        }
        &:last-child:after {
          display: none;
        }
      }
    }

    section > div > header {
      max-width: 860px;
      margin: 2rem auto;
      filter: drop-shadow(0px 0px 6px #f5f3ef) drop-shadow(0px 0px 3px #f5f3ef) drop-shadow(0px 0px 1px #f5f3ef);
      width: 80%;

      h2 {
      }

      p {
        font-size: 1.1rem;
        max-width: 50rem;
        margin: 0 auto;
        text-align: center;
        line-height: 1.5em;
        color: #543e33;
      }
    }
  }
}


a.hsbtn {
  @extend .bpt;
  font-size: .9rem;
  text-decoration: none;
  background: #ffffff;
  color: $hsc-brown;
  padding: 1.2rem 3.5rem;
  margin-top: 1rem;
  display: inline-block;
  border-radius: 3rem;

  &.dark {
    color: #ffffff;
    background: $hsc-brown;
  }
}


@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.slide-fade-enter-active {
  transition: all .3s ease;

  img {
    transition: all .5s ease;
  }
}

.slide-fade-leave-active {
  transition: all .3s ease;
  //img {
  //  transition: all .5s ease;
  //}
}

.slide-fade-enter,
.slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateY(-1rem);
  opacity: 0;

}

.slide-fade-enter {
  //img {
  //  transform: scale(1.1);
  //}
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .9s;

}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}


.slide-down-enter-active, .slide-down-leave-active {
  transition: all .2s;
  opacity: 1;
  transform: scale(1) !important;


}

.slide-down-enter, .slide-down-leave-to {
  opacity: 0;

  transform: scale(1.2) !important;;
}

.indent1 {
  margin-left: 1rem;
}

.indent2 {
  margin-left: 2rem;
}

.saucecard {
  display: inline-block;
  text-decoration: none;
  color: $hsc-brown;

  img {
    display: block;
    margin: 0 auto;
    margin-bottom: 1em;
  }


  div.copy div {
    @extend .bpt;
    font-size: 1.6rem;
  }

  small {
    margin-top: .2rem;
    font-family: 'GothamBold', 'Gotham Book', Helvetica, Arial, sans-serif;
    font-size: 1rem;
    margin-bottom: 2rem;
    display: block;
  }

  p {
    max-width: 80%;
    margin: 0 auto;
  }
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url(/fonts/MaterialIcons-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'BrandonPrinted-Two';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/BrandonPrinted-Two.ttf) format('truetype');
}

@font-face {
  font-family: 'GothamBook';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Gotham-Book.otf) format('opentype'),
  url(/fonts/Gotham-Book.ttf) format('truetype');
}

@font-face {
  font-family: 'GothamBold';
  font-style: normal;
  font-weight: 600;
  src: url(/fonts/Gotham-Bold.otf) format('opentype'),
  url(/fonts/Gotham-Bold.ttf) format('truetype');
}

body {
  font-family: 'GothamBook', 'Gotham Book', Helvetica, Arial, sans-serif;
}


.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

section#hero {
  width: 100%;
  list-style: none;
  height: 80vw;
  max-height: 720px;
  position: relative;
  overflow: hidden;
  -webkit-transition: background-image .5s ease-in-out;
  transition: background-image .5s ease-in-out;

  &.one {
    background-image: url("~@/../public/images/sizes/_50/HP-Header-1.jpg"), url("~@/../public/images/sizes/_50/HP-Header-2.jpg");
  }

  &.two {
    background-image: url("~@/../public/images/sizes/_50/HP-Header-2.jpg");
  }

  background-size: cover;
  background-position: center right;

  div.copy-container {
    position: absolute;
    bottom: 2rem;
    top: 2rem;
    right: 4rem;
    width: 30vw;
    min-width: 320px;
    z-index: 3;
    display: flex;
    align-content: center;
    align-items: center;
    padding-top: 6rem;

    .inner {
      flex: 1;
      filter: drop-shadow(0px 0px 10px rgb(0, 0, 0)) drop-shadow(0px 0px 20px rgb(0, 0, 0));
      -webkit-filter: drop-shadow(0px 0px 10px rgb(0, 0, 0)) drop-shadow(0px 0px 20px rgb(0, 0, 0));

      .bpt {
        font-size: 2rem;
        font-family: 'BrandonPrinted-Two', "Arial Black", sans-serif;
        text-transform: uppercase;
      }

      text-align: center;
      color: #ffffff;

      p {
        line-height: 1.5em;
      }
    }

  }


  div.logo-container {
    z-index: 2;
    width: 100%;
    overflow: hidden;

    img {
      left: 50%;
      width: 100%;
      position: absolute;
      top: 0;
      transform: translateX(-50%);

      &.logo {
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        width: 25vw;
        max-width: 400px;
        image-rendering: -moz-crisp-edges; /* Firefox        */
        image-rendering: -o-crisp-edges; /* Opera          */
        image-rendering: -webkit-optimize-contrast; /* Safari         */
        image-rendering: optimize-contrast; /* CSS3 Proposed  */
        -ms-interpolation-mode: nearest-neighbor; /* IE8+           */
      }
    }
  }

  @media(min-width: 1024px) {

    max-height: 50vw;
  }
  @media (max-width: 720px) {

    &.one {
      background-image: url("/images/sizes/_50/HP-Header-1.jpg"), url("/images/sizes/_15/HP-Header-1.png");
    }

    &.two {
      background-image: url("/images/sizes/_50/HP-Header-2.jpg"), url("/images/sizes/_15/HP-Header-2.jpg");
    }

    height: 134vh;
    max-height: 720px;
    background-position: 20%;
    div.copy-container {
      width: auto;
      left: 1rem;
      right: 1rem;
      top: auto;
      bottom: 2rem;
      padding: 2rem;
      background: rgba(0, 0, 0, 0.5);
      min-width: 0;
      width: auto;
    }
    div.logo-container {
      img.shadow {
        max-width: 160vw;
        width: 160vw;
      }

      img.logo {
        max-width: 50%;
        width: 290px;
        filter: drop-shadow(0 0 20px #000000);
      }
    }
  }

  ul {
    li {
      height: 100%;
      width: 100%;
      position: absolute;
      text-align: center;

      picture {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        pointer-events: none;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

$bp: 960px;
.for-desktop {
  display: none;
  @media(min-width: $bp+1) {
    display: inherit;
  }
}

.for-mobile {
  @media(min-width: $bp+1) {
    display: none;
  }
}
</style>
