import Vue from 'vue'
import App from './App.vue'
import router from './router'
import data from "@/data";
// import data from '@/data.json';

Vue.config.productionTip = false
Vue.config.runtimeCompiler = true
Vue.prototype.$hscData = data;

let passiveSupported = false;

try {
  const options = {
    get passive() { // This function will be called when the browser
      //   attempts to access the passive property.
      passiveSupported = true;
      return false;
    }
  };

  window.addEventListener("test", null, options);
  window.removeEventListener("test", null, options);
} catch(err) {
  passiveSupported = false;
}


new Vue({
  watch: {},
  mounted() {
    this.handleScroll = _.throttle((e) => {
      let vm = this
      let st = window.scrollY
      if (st > this.lastScrollTop) {
        this.down = true
      } else {
        this.down = false
      }
      this.lastScrollTop = st
      if (!this.scrollStarted) {
        this.scrollStarted = true
      }
      vm.$set(this, 'scrollPosition', document.body.scrollTop);
    }, 60).bind(this);
    document.body.addEventListener('scroll', this.handleScroll, passiveSupported ? { passive: true } : false)
  },
  unmounted() {
    document.body.removeEventListener('scroll', this.handleScroll)
  },
  data: function () {
    return {
      lang: 'en',
      scrollPosition: 0,
      active: null,
      lastActive: null,
      lastScrollTop: window.pageYOffset || document.documentElement.scrollTop,
      scrollStarted: false,
      down: true,
      hscData: this.$hscData,
      wait: false,
    };
  }, methods: {},
  router,
  render: function (h) {
    return h(App)
  }
}).$mount('#app')



