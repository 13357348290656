<template>
  <section id="accessibility">
    <div>
      <header>
        <h2 v-html="page.title"/>
      </header>
      <div v-html="page.content"/>
    </div>
  </section>
</template>
<script>
export default {
  data: function() {
    return {
      hscData: this.$hscData,
      page: this.$hscData.pages.accessibility

    };
  },
  created() {
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
section#accessibility {
  max-width: 1024px;
  margin: 0 auto;
  padding: 2rem;
}
</style>
