<template>
  <footer id="bottom">
    <div class="flex">
      <div>
        <h2 v-html="content.header[$root.lang]"></h2>
      </div>
      <div class="social">
        <a tabindex=0 aria-label="Visit the Homestead Chef YouTube"
           href="https://www.youtube.com/channel/UCKo00cOwo5sqr-a7onu_jBA" target="_blank"><i
            class="fa fa-youtube fa-lg"></i></a>
        <a tabindex=0 aria-label="Visit Homestead Chef on Facebook" href="https://www.facebook.com/homesteadchefstable"
           target="_blank"><i class="fa fa-facebook fa-lg"></i></a>
        <!--        <a href="#"><i class="fa fa-twitter fa-lg"></i></a>-->
        <a tabindex=0 aria-label="Visit Homestead Chef on Instagram"
           href="https://www.instagram.com/homesteadchefstable" target="_blank"><i
            class="fab fa-instagram fa-lg"></i></a>
        <!--        <a href="#"><i class="fab fa-pinterest-p fa-lg"></i></a>-->
      </div>
      <!--      <div>-->
      <!--        <a href="" class="hsbtn" v-html="content.subscribe[$root.lang]"/>-->
      <!--      </div>-->
    </div>
    <aside>
      <span class="copyright">&copy; {{ new Date().getFullYear() }} Homestead Chef's Table. All rights reserved.</span>
      <span><router-link to="/terms">PRIVACY POLICY / TERMS</router-link></span>
      <span><router-link to="/accessibility">ACCESSIBILITY</router-link></span>
    </aside>
  </footer>
</template>
<script>
export default {
  data: function() {
    return {
      hscData: this.$hscData,
      content: this.$hscData.content.footer,
      overlay: true,
    }
  },
  // computed: {
  //   content() {
  //     return
  //   }
  // },
  mounted() {
    this.clicked.bind(this)
  },
  unmounted() {
  },
  methods: {
    clicked: (ev) => {
      ev.target.style.display = 'none';
      this.overlay = false;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/_variables.scss";

footer {
  background: url('/images/footerbg.jpg');
  background-size: cover;
  padding: 4rem 2rem;
  background-blend-mode: multiply;
  background-color: #b5b5b5;

  .copyright {
    opacity: .5;
    white-space: normal;
  }

  .flex {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    color: #ffffff;
    flex-direction: column;


    h2 {
      color: #ffffff;
      text-align: left;

      margin: 0;
      padding: 0;
    }

    > div {
      flex: 1;
      padding: 0;
      margin: 0;
      align-items: center;
      align-content: center;
      justify-content: space-between;

      &:nth-child(1) {
        text-align: left;
      }

      &.social {
        margin-top: 1rem;
        margin-bottom: 2rem;
        text-align: center;

        a {
          font-size: 2rem;
          color: #ffffff;
          display: inline-block;
          width: 3rem;
          height: 3rem;
          box-sizing: border-box;
          border: 2px solid #ffffff;
          border-radius: 2rem;

          i {
            padding: 0;
            font-size: 20px;
            color: #ffffff;
          }

          + a {
            margin-left: 1rem;
          }
        }
      }

      &:nth-child(3) {
        text-align: right;
      }


    }

    @media (max-width: 720px) {
      display: block;
      flex: none;
      div, h2 {
        text-align: center !important;

        display: block;
        margin: 2rem 1rem;

        &:nth-child(1) {
          margin-bottom: 3rem;
        }
      }
    }
  }

  aside {
    display: block;
    width: 100%;
    margin-top: 2rem;
    align-content: center;
    align-items: center;
    text-align: center;

    span, a {
      color: #ffffff;
      text-decoration: none;
    }

    span {
      margin: .75rem;
      opacity: .7;
    }

    @media (max-width: 720px) {
      span {
        display: block;
        margin: 2rem 1rem;

        &:nth-child(1) {
          margin-bottom: 3rem;
        }
      }
    }
  }
}

</style>
