<script>
export default {
  data() {
    return {
      hscData: this.$hscData,
      content: this.$hscData.content.wheretobuy,
      overlay: true,
    }
  },
  mounted() {
    this.clicked.bind(this)
  },
  unmounted() {
  },
  methods: {
    clicked: (ev) => {
      ev.target.style.display = 'none';
      this.overlay = false;
    }
  }
}
</script>
<template>
  <section id="wheretobuy">
    <div>
      <img src="/images/products.png" alt="">
      <h2>Available to purchase online</h2>
      <div><a target="_blank" class='hsbtn dark'
              href="https://www.sunsweet.com/homestead-chef-sauces">Shop Now</a></div>
    </div>
  </section>
</template>
<style lang="scss">
@import "@/_variables.scss";

#wheretobuy {
  background: url("/images/sizes/_80/HP-Where-To-Buy.png") center center;
  background-size: cover;
  min-height: 29vw;
  text-align: center;
  padding: 2rem 1rem 4rem;
  display: flex;
  flex-direction: column;

  > div {
    @media(max-width: 640px) {
      flex: 1;
    }
    display: grid;
    align-content: center;

    img {
      display: block;
      margin: 0 auto;
      width: 35rem;
      margin-top: 1rem;
      max-width: 64%;
    }
  }

  .locations {
    width: 60%;
    margin: 3rem auto;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;

    li {
      margin-bottom: 4rem;
      color: $hsc-brown;

      strong {
        display: block;
        font-family: 'GothamBold', 'Gotham Book', Helvetica, Arial, sans-serif;
        margin-bottom: 1rem;
      }
    }
  }

  @media (max-width: 720px) {
    .locations {
      grid-template-columns: auto;
      margin-bottom: 8rem;
    }
    background-position: 30% 40%;
  }
}

</style>
