export default {
    nav: {
        'our-sauces': {label: 'Our Sauces'},
        'howtouse': {label: 'How To Use'},
        'recipes': {label: 'Recipes'},
        'wheretobuy': {label: 'Where To Buy'},
    },
    hero: {
        'title': 'Dinner Ready&nbsp;in&nbsp;5',
        'desc': 'Did we make the easiest weeknight dinner more delicious? We sure did. Our sauces bring more flavor & juiciness to rotisserie chicken for a gourmet taste without the stress!  Just shred or section, pour the sauce, heat it up, and dinner is done.',
        backgrounds: {
            'hero1': {
                'image': 'images/sizes/_30/HP-Header-1.jpg',
            },
            'hero2': {
                'image': 'images/sizes/_30/HP-Header-2.jpg',
            },
        }
    },
    sauces: {
        'classic-country-bbq': {
            'buyURL': 'https://www.sunsweet.com/store/homestead-classic-country-bbq-sauce',
            'bgcolor': '#691a1b',
            'image': 'SS_Sauces_Rot_BBQ_Rend_REV3.png',
            'id': 'rec8cNWYlHUHiXjVb',
            'label': 'Classic Country BBQ',
            'htmlLabel': 'Classic<BR> Country BBQ',
            'fcolor': '#7b1f20',
            'slug': 'classic-country-bbq',
            features: ['5min', '25less', 'glutenfree', 'nutfree', 'pj', 'vegan'],
            'desc': 'Sweet, savory, a little tang, and plenty of twang: we put all the flavors of a backyard barbeque in one easy-to-use pouch.\n\n',
            'createdTime': '2020-09-14T12:19:25.000Z',
            'dek': 'Bring the backyard barbecue inside.',
            'longdesc': 'Sweet, savory, a little tang, and plenty of twang: we put all the flavors of a backyard barbeque in one little pouch. All you have to do is pour over a rotisserie chicken your kitchen just became the new barbecue hot spot.',
            'ingredients': [
                'Tomato puree (water, tomato paste)',
                'Plum juice (water, plum concentrate)',
                'Apple Cider Vinegar',
                'Dried Plum Puree',
                'Sugar',
                'Salt',
                'Ancho Pepper',
                'Onion',
                'Paprika',
                'Black Pepper',
                'Garlic',
                'Crushed red peppers',
                'Natural flavors',
            ],
            'aps': [
                [0, 'Calories', '50'],
                [0, 'Total Fat', '0g', '0%'],
                [1, 'Saturated Fat', '0g', '0%'],
                [1, 'Trans Fat', '0g', ''],
                [0, 'Cholesterol', '0mg', '0%'],
                [0, 'Sodium', '260mg', '11%'],
                [0, 'Total Carbohydrate', '9g', '3%'],
                [1, 'Dietary Fibers', '1g', '4%'],
                [1, 'Total Sugars', '7g', ''],
                [2, 'Includes', '2g Added Sugars', '4%'],
                [0, 'Proteins', '1g', '']
            ],
            vm: [
                ['Vitamin D', '0mcg', '0%'],
                ['Calcium', '13mg', '2%'],
                ['Iron', '0mg', '0%'],
                ['Potassium', '247mg', '6%']
            ]
        },
        'pacific-rim-garlic': {
            'buyURL': 'https://www.sunsweet.com/store/homestead-pacific-rim-sauce',
            'bgcolor': '#4a1444',
            'image': 'SS_Sauces_Rot_PacRim_Rend_REV3.png',
            'id': 'recYGgcgLYYOWjoXb',
            'label': 'Pacific Rim Garlic',
            'htmlLabel': 'Pacific Rim<br> Garlic',
            'fcolor': '#7e4278',
            'slug': 'pacific-rim-garlic',
            features: ['5min', '25less', 'glutenfree', 'nutfree', 'pj', 'vegan'],
            'desc': 'Garlic, onion, red pepper, natural soy sauce – all the Asian flavors you love, together in one delicious sauce.',
            'dek': 'Turn dinner into a delicious destination.',
            'longdesc': 'Natural soy sauce, garlic, onion, red pepper, and a dash of sweet flavor all blend together in our Pacific Rim Garlic Rotisserie Sauce. And the best part? Just pour it over a rotisserie chicken and your Asian-inspired dinner is served. No takeout orders needed!',
            'ingredients': [
                'Plum juice (water, plum concentrate)',
                'Dried plum concentrate',
                'Rice vinegar',
                'Tamari soy sauce',
                'Salt',
                'Sugar',
                'Alcohol vinegar',
                'Corn starch',
                'Garlic',
                'Crushed red peppers',
                'Natural flavors',
            ],
            'aps': [
                [0, 'Calories', '90'],
                [0, 'Total Fat', '0g', '0%'],
                [1, 'Saturated Fat', '0g', '0%'],
                [1, 'Trans Fat', '0g', ''],
                [0, 'Cholesterol', '0mg', '0%'],
                [0, 'Sodium', '430mg', '19%'],
                [0, 'Total Carbohydrate', '20g', '7%'],
                [1, 'Dietary Fibers', '1g', '4%'],
                [1, 'Total Sugars', '11g', ''],
                [2, 'Includes', '9g Added Sugars', '18%'],
                [0, 'Proteins', '1g', '']
            ],
            vm: [
                ['Vitamin D', '0mcg', '0%'],
                ['Calcium', '14mg', '2%'],
                ['Iron', '0mg', '0%'],
                ['Potassium', '197mg', '4%']
            ]
        },
        'spicy-buffalo': {
            'buyURL': 'https://www.sunsweet.com/store/homestead-spicy-buffalo-sauce',
            'bgcolor': '#146196',
            'image': 'SS_Sauces_Rot_Buffalo_Rend_REV3.png',
            'id': 'recgH3epLY090i1zt',
            'label': 'Spicy Buffalo',
            'htmlLabel': 'Spicy<br> Buffalo',
            'fcolor': '#3676a9',
            'slug': 'spicy-buffalo',
            features: ['5min', '25less', 'glutenfree', 'nutfree', 'pj', 'vegan'],
            'desc': 'Time to turn up the heat! Aged cayenne red peppers kick things up a notch in our Spicy Buffalo Rotisserie Sauce.\n',
            'createdTime': '2020-09-14T12:14:09.000Z',
            'dek': 'Spice up your dinner.',
            'longdesc': 'Time to turn up the heat! Aged cayenne red peppers pack a punch in our Spicy Buffalo Rotisserie Sauce. Add it to rotisserie chicken for a flavor-packed meal your family will love – maybe give them a little ranch to cool things down.',
            'ingredients': [
                'Plum juice (water, plum concentrate)',
                'Aged Cayenne Red Peppers',
                'Distilled vinegar',
                'Water',
                'Salt',
                'Garlic Powder',
                'Corn starch',
                'Natural flavors'
            ],
            'aps': [
                [0, 'Calories', '45'],
                [0, 'Total Fat', '1g', '1%'],
                [1, 'Saturated Fat', '0g', '0%'],
                [1, 'Trans Fat', '0g', ''],
                [0, 'Cholesterol', '0mg', '0%'],
                [0, 'Sodium', '900mg', '39%'],
                [0, 'Total Carbohydrate', '8g', '3%'],
                [1, 'Dietary Fibers', '0g', '0%'],
                [1, 'Total Sugars', '4g', ''],
                [2, 'Includes', '1g Added Sugars', '2%'],
                [0, 'Proteins', '0g', '']
            ],
            vm: [
                ['Vitamin D', '0mcg', '0%'],
                ['Calcium', '9mg', '0%'],
                ['Iron', '0mg', '0%'],
                ['Potassium', '102mg', '2%']
            ]
        },
        'lemon-herb': {
            'buyURL': 'https://www.sunsweet.com/store/homestead-lemon-herb-sauce',
            'bgcolor': '#3e6714',
            'image': 'SS_Sauces_Rot_LemHerb_Rend_REV3.png',
            'id': 'recqYF9uRai3br1xJ',
            'label': 'Lemon Herb',
            'htmlLabel': 'Lemon<br> Herb',
            'fcolor': '#779d41',
            'slug': 'lemon-herb',
            features: ['5min', '25less', 'glutenfree', 'nutfree', 'pj', 'vegan'],
            'desc': 'Tangy lemon meets tasty herbs meets your dinner plate. This Italian Classic packs tons of flavor in one little pouch.',
            'createdTime': '2020-09-14T12:14:09.000Z',
            'dek': 'Make an Italian classic. The easy way!',
            'longdesc': 'Tangy lemon meets tasty herbs meets your dinner plate. Turn your kitchen into a quaint Italian bistro in three easy steps: tear, pour over rotisserie chicken, serve. Buon appetito!',
            'ingredients': [
                'Plum juice (water, plum concentrate)',
                'Lemon Juice',
                'Corn starch',
                'Yeast Extract',
                'Red Bell Pepper',
                'Salt',
                'Garlic Powder',
                'Dehydrated Orange Peel',
                'Shallots',
                'Natural flavors',
                'Onion Powder',
                'Parsley',
                'Basil',
                'Crushed red peppers',
            ],
            'aps': [
                [0, 'Calories', '35'],
                [0, 'Total Fat', '0g', '0%'],
                [1, 'Saturated Fat', '0g', '0%'],
                [1, 'Trans Fat', '0g', ''],
                [0, 'Cholesterol', '0mg', '0%'],
                [0, 'Sodium', '360mg', '16%'],
                [0, 'Total Carbohydrate', '8g', '3%'],
                [1, 'Dietary Fibers', '0g', '0%'],
                [1, 'Total Sugars', '3g', ''],
                [2, 'Includes', '0g Added Sugars', '0%'],
                [0, 'Proteins', '1g', '']
            ],
            vm: [
                ['Vitamin D', '0mcg', '0%'],
                ['Calcium', '9mg', '0%'],
                ['Iron', '0mg', '0%'],
                ['Potassium', '81mg', '2%']
            ]
        }
    },
    recipes: {
        'mini-bbq-chicken-pies': {
            label: 'Mini BBQ Chicken Pies',
            image: 'Recipe-Mini-BBQ-Pies.jpg',
            instructions: [
                'Preheat toaster oven or conventional oven to 425˚F.',
                'Stir together Classic Country BBQ sauce, chicken, vegetables, broth and green onions; set aside.',
                'On lightly floured work surface, unroll puff pastry sheet. Using 10 oz ramekin as guide, cut out 4 rounds to fit top of ramekin (reserve remaining pastry for another use).',
                'Divide chicken mixture among four 10-oz ramekins and top with pastry rounds. Cut steam vent in center of each pastry top. Whisk egg with milk; brush over pastry.',
                'Bake for 15 to 20 minutes or until pastry is golden brown and filling is bubbling.'
            ],
            tip: ['Don’t have ramekins? Use mini pie pans instead!'],
            preptime: '10 min',
            cookingtime: '20 hours',
            serves: '4',
            featured: ['classic-country-bbq'],
            desc: 'Topped with puff pastry, these easy-to-assemble mini pot pies are filled with rotisserie chicken, vegetables and Classic Country BBQ Rotisserie Sauce for a quick dinner any night of the week. ',
            ingredients: [
                'For the mini pies',
                ['1 cup', 'Homestead Chef’s Table Classic Country BBQ Rotisserie Sauce'],
                ['2 cups', 'chopped rotisserie cooked chicken'],
                ['2 cups', 'frozen vegetable mix (corn, peas and carrots), thawed'],
                ['1/2 cup', 'sodium-reduced chicken broth'],
                ['4', 'green onions, thinly sliced'],
                ['1 sheet', 'frozen puff pastry, thawed'],
                ['1 egg'],
                ['1 tbsp', 'milk']
            ]
        },
        'bbq-patatas-bravas-with-chicken': {
            label: 'BBQ Patatas Bravas with Chicken',
            image: 'Recipe-BBQ-Patatas-Bravas.jpg',
            desc: 'This quick and easy take on a Spanish classic turns this side dish into dinner in minutes.',
            preptime: '5 minutes',
            cooktime: '20 minutes',
            totaltime: '25 minutes',
            serves: '4',
            featured: ['spicy-buffalo'],
            ingredients: [
                ['1/2 cup', 'Homestead Chef’s Table Spicy Buffalo Rotisserie Sauce'],
                ['1 pkg', '(16 oz) frozen potato wedges'],
                ['2 cups', 'shredded rotisserie cooked chicken'],
                ['1 cup', 'shredded Cheddar cheese'],
                ['2', 'green onions, thinly sliced']
            ],
            instructions: [
                'Bake potato wedges according to package directions in toaster oven or conventional oven, adding chicken to pan in last 5 minutes of cooking time to heat through.',
                'Toss potato wedges and chicken with Spicy Buffalo Rotisserie Sauce.',
                'To serve, sprinkle cheese and green onions over top.'
            ],
            tip: ['Want to add a superfood to the mix? Swap in sweet potato wedges for the potato wedges!']

        },
        'spicy-buffalo-chicken-power-bowl': {
            label: 'Spicy Buffalo Chicken Power Bowl',
            image: 'Recipe-Spicy-Buffalo-Power-Bowl.jpg',
            desc: 'Perfect for busy weeknights, this nutritious Tex Mex–style quinoa and chicken bowl is one that the whole family will enjoy.',
            preptime: '5 minutes',
            cooktime: '5 minutes',
            totaltime: '10 minutes',
            serves: '4',
            featured: ['spicy-buffalo'],
            videoId: 'YJrJHHMwSEY',
            ingredients: [
                ['1/2 cup', 'Homestead Chef’s Table Spicy Buffalo Rotisserie Sauce'],
                ['1 1/2 cups', 'cubed rotisserie cooked chicken'],
                ['1 pkg', '(8 oz) ready-to-serve quinoa'],
                ['1 can', '(15 oz) corn, drained '],
                ['1 can', '(15 oz) black beans, drained'],
                ['1', 'large tomato, diced'],
                ['1', 'ripe avocado, halved, pitted, peeled and sliced'],
                ['1/3 cup', 'ranch dressing'],
                ['2 tbsp', 'cilantro, chopped']
            ],
            instructions: [
                'Mix together Spicy Buffalo sauce and chicken. Transfer mixture to microwave-safe dish; heat in microwave for 2 to 3 minutes, stirring every 60 seconds, until heated through.',
                'Heat quinoa in microwave according to package directions.',
                'Divide quinoa among 4 bowls. Top with chicken mixture, corn, black beans, tomato and avocado; drizzle with ranch dressing. Garnish with cilantro.'
            ],
            tip: ['For extra kick, add sliced pickled jalapeño peppers to taste.']
        },
        'pacific-rim-garlic-chicken-stir-fry': {
            label: 'Pacific Rim Garlic Chicken Stir-Fry',
            image: 'Recipe-Pacific-Rim-Garlic-Chicken_Stir-Fry.jpg',
            desc: 'Faster than take-out, this satisfying chicken stir-fry is loaded with vegetables and flavor.',
            preptime: '5 minutes',
            cooktime: '10 minutes',
            totaltime: '15 minutes',
            serves: '4',
            featured: ['pacific-rim-garlic'],
            ingredients: [
                ['1 1/2 cups', 'Homestead Chef’s Table Pacific Rim Garlic Rotisserie Sauce'],
                ['1 pkg', '(8 oz) ready-to-serve jasmine rice'],
                ['1 1/2 cups', 'cubed rotisserie cooked chicken'],
                ['1 bag', '(12 oz) frozen stir-fry vegetable medley, thawed'],
                ['4', 'green onions, thinly sliced'],
            ],
            instructions: [
                'Heat rice in microwave according to package directions.',
                'Meanwhile, in large skillet set over medium-high heat, heat Pacific Rim Garlic Sauce and chicken; bring to simmer. Stir in vegetables; cook for 3 to 5 minutes or until mixture is simmering and heated through.',
                'Divide rice among 4 plates. Top with chicken and vegetable stir-fry; garnish with green onions',
            ],
            tip: ['Add a bit of crunch by topping with toasted peanuts or cashews.']
        },
        'lemon-and-herb-chicken-salad-bowl': {
            label: 'Lemon and Herb Chicken Salad&nbsp;Bowl',
            image: 'Recipe-Lemon-Herb-Chicken-Salad.jpg',
            desc: 'This big chicken salad with a lemony herb sauce makes a great dinner or grab-and-go lunch.',
            preptime: '5 minutes',
            cooktime: '5 minutes',
            serves: '4',
            featured: ['lemon-herb'],
            ingredients: [
                ['1/2 cup', 'Homestead Chef’s Table Lemon Herb Rotisserie Sauce'],
                ['1 1/2 cups', 'cubed rotisserie cooked chicken'],
                ['1 pkg', '(5 oz) baby spinach'],
                ['1/3 cup', 'slivered almonds'],
                ['1/3 cup', 'dried cranberries'],
            ],
            instructions: [
                'Toss Lemon Herb Sauce with chicken until well coated.',
                'Divide spinach among 4 bowls. Top with chicken, almonds and dried cranberries.',
            ],
            tip: ['You can easily swap pecans or walnuts for almonds, and arugula or kale for the baby spinach!']
        },
        'bbq-chicken-sliders': {
            label: 'BBQ Chicken Sliders',
            image: 'Recipe-BBQ-Chicken-Sliders.jpg',
            desc: 'Pulled chicken sliders are always a hit with a hungry crowd. Serve these tasty mini-sandwiches for lunch or dinner, or even as an appetizer or snack.',
            preptime: '5 minutes',
            cooktime: '5 minutes',
            totaltime: '10 minutes',
            serves: '4',
            featured: ['classic-country-bbq'],
            videoId: 'qpWa9xCCkpQ',
            ingredients: [
                ['3/4 cup', 'Homestead Chef’s Table Classic Country BBQ Rotisserie Sauce'],
                ['1 bag', '(12 oz) coleslaw mix '],
                ['1/3 cup', 'prepared coleslaw dressing'],
                ['2 cups', 'shredded rotisserie cooked chicken'],
                ['12 slider buns', 'split and toasted'],
            ],
            instructions: [
                'Toss coleslaw mix with dressing; set aside.',
                'Toss Classic Country BBQ Sauce with chicken until well coated. Transfer to microwave-safe dish; heat in microwave for 2 to 3 minutes, stirring every 60 seconds, until heated through.',
                'Assemble chicken and coleslaw in buns.'
            ],
            tip: ['Add a slice of cheddar or monterey jack cheese to each slider for a bit of cheesy goodness.']
        },
        'pacific-rim-garlic-chicken-with-noodles': {
            label: 'Pacific Rim Garlic Chicken with Noodles',
            image: 'Recipe-Pacific-Rim-Chicken.jpg',
            desc: 'Ready in minutes, this satisfying noodle dish makes a tasty dinner that everyone will love.',
            preptime: '5 minutes',
            cooktime: '10 minutes',
            totaltime: '15 minutes',
            serves: '4',
            featured: ['pacific-rim-garlic'],
            ingredients: [
                ['1 1/2 cups', 'Homestead Chef’s Table Pacific Rim Garlic Rotisserie Sauce'],
                ['8 oz', 'vermicelli cellophane noodles'],
                ['4 tsp', 'vegetable oil'],
                ['1 1/2 cups', 'shredded rotisserie cooked chicken'],
                ['1', 'red pepper, thinly sliced'],
                ['1 cup', 'bean sprouts'],
                ['2 tbsp', 'finely chopped fresh cilantro (optional)'],
            ],
            instructions: [
                'Cook noodles in boiling water for 1 to 2 minutes or until pliable. Drain and toss with oil. Transfer to serving dish.',
                'Meanwhile, in large skillet set over medium-high heat, heat Pacific Rim Garlic Rotisserie Sauce and chicken; bring to simmer. Stir in vegetables; cook for 3 to 5 minutes or until mixture is simmering and heated through. ',
                'Top noodles with red pepper slices, chicken and vegetable stir-fry. Garnish with bean sprouts and cilantro (if using). ',
            ],
            tip: [
                'Alternatively, soak cellophane noodles for 3 to 5 minutes in hot water or until pliable. Drain and rinse in cold water. Stir fry in wok or large skillet with vegetable oil for 2 to 3 minutes or until heated through.',
                'Use a medley of yellow, red and green pepper slices for a colorful addition.',
                'Garnish with 4 tsp of toasted sesame seeds if desired.',
            ]
        },
        'lemon-herb-mediterranean-rice-bowls': {
            label: 'Lemon Herb Mediterranean Rice Bowls',
            image: 'Recipe-Lemon-Herb-Mediterranean-Rice-Bowl.jpg',
            desc: 'With Greek and Italian flavors, these yummy rice bowls make a speedy supper that’s fresh and light.',
            preptime: '5 minutes',
            cooktime: '5 minutes',
            totaltime: '10 minutes',
            serves: '4',
            featured: ['lemon-herb'],
            ingredients: [
                ['3/4 cup', 'Homestead Chef’s Table Lemon Herb Rotisserie Sauce'],
                ['1 1/2 cups', 'shredded rotisserie cooked chicken'],
                ['1/2 cup', 'sliced onions'],
                ['1/2', 'red pepper, sliced'],
                ['1/4 cup', 'sliced pitted black olives'],
                ['1/4 cup', 'sliced pepperoncini peppers'],
                ['2 cups', 'ready-to-serve white rice'],
                ['1/3 cup', 'crumbled feta cheese (3 oz)'],
                ['Lemon slices (optional)'],
            ],
            instructions: [
                'In large skillet set over medium-high heat, heat Lemon Herb Rotisserie Sauce and chicken; bring to simmer. Stir in onions, red pepper slices, olives and pepperoncini; cook for 3 to 5 minutes or until veggies are tender and chicken is heated through. ',
                'Meanwhile, prepare ready-to-serve rice according to package directions. Transfer to serving dish.',
                'Spoon lemon herb chicken mixture over rice. Garnish with feta cheese, and lemon slices (if using).',
            ],
            tip: [
                'Substitute brown rice for white rice if desired.',
                'Sprinkle dish with finely chopped parsley or dill before serving if desired.',
                'Substitute sliced pickled banana peppers for pepperoncini or, for less spicy options, substitute sliced jarred roasted red peppers.',
            ]
        }
    },
    content: {
        hero: {
            header: {
                'en': 'DINNER READY IN 5'
            },
            body: {
                'en': 'Did we make the easiest weeknight dinner more delicious? We sure did. Our sauces bring more flavor & juiciness to rotisserie chicken\u0003for a gourmet taste without the stress!\u0003Just shred or section, pour the sauce, heat it up, and dinner is done.\n'
            },
        },

        exploresauces: {
            header: {
                en: 'EXPLORE OUR SAUCES'
            },
        },

        howtouse: {
            body: {
                en: 'Become the star chef you always knew you could be. It’s as simple as tear, pour, done! But, if you’re craving more details, watch our videos below! They’re pretty short, because, well – making meals with Homestead Chef Sauces is pretty quick. \n'
            },
            header: {
                en: 'GOURMET, MADE EASY!'
            },
        },

        wheretobuy: {
            header: {
                'en': 'WHERE TO BUY'
            },
            viewmore: {
                'en': 'VIEW FULL LIST'
            },
            enterzip: {
                'en': 'Enter your zip code'
            },
        },

        'nutritional_information': {
            'en': 'Nutritional Information'
        },
        'ingredients': {
            'en': 'Ingredients'
        },
        'instructions': {
            'en': 'Instructions'
        },
        'in_this_recipe': {
            'en': 'In This Recipe'
        },
        'bestsellers': {
            'en': 'OUR BESTSELLERS'
        },
        'rotisserie_sauce': {
            'en': 'Rotisserie Sauce'
        },

        footer: {
            header: {
                'en': 'CONNECT WITH US'
            },
        },

        recipes: {
            header: {
                'en': 'ONE POUCH. SO MANY WAYS TO&nbsp;USE&nbsp;IT.'
            },
            body: {
                'en': 'Check out our easy-to-follow, not-too-many-steps recipes! With Homestead, you don’t have to get fancy to feel like a celebrity chef. With so little time spent on making your meals, you’ll have plenty of time to enjoy your newfound fame.\n'
            },
            view: {
                'en': 'VIEW RECIPE'
            },
        },

        videos: {
            video1: {
                poster: 'images/sizes/_80/HP-Video-1.jpg',
                title: {
                    'en': 'ROTISSERIE SAUCE: How-To Video'
                },
                desc: {
                    'en': 'What is ‘Rotisserie Sauce’? How do I use it?'
                },
                ID: 'qpWa9xCCkpQ'

            },

            video2: {
                poster: 'images/sizes/_80/HP-Video-2.jpg',
                title: {
                    'en': 'How to Make A Spicy Buffalo Chicken Power Bowl'
                },
                desc: {
                    'en': 'Whip up this tasty dish in just 5 minutes!'
                },
                ID: 'YJrJHHMwSEY'
            }
        },
        sharethis: {
            'en': 'Share this'
        },
        features: {
            '5min': 'Meal done in five minutes',
            '25less': '25% less sodium and sugar',
            'glutenfree': 'Gluten Free',
            'nutfree': 'Nut Free',
            'pj': 'Naturally Sweetened with Prune Juice',
            'vegan': 'Vegan'
        }
    }
}
