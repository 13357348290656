<script>
export default {
  props: ['item', 'details', 'type', 'desc'],
  data: function () {
    return {
      wait: false,
      wxdata: {Info: {}, Translations: {}, Meta: {}},
      flightRules: null,
    }
  },
  mounted() {
  },
  methods: {}
}
</script>
<template>
  <router-link tag="a" class="saucecard" active-class="active" :key="'/sauces/' + item.slug"
               :to="{ path: '/sauces/' + item.slug }">
    <div class="photo">
      <img :src="`/images/sizes/_15/${item.image}`" width="100" alt="">
    </div>
    <div class="copy">
      <figure class="bg" :style="`background-color: ${item.bgcolor}`"></figure>
      <div v-html="item.htmlLabel"/>
      <small v-show="details || type">{{ $hscData.content.rotisserie_sauce[$root.lang] }}</small>
      <p v-show="details || desc">{{ item.desc }}</p>
    </div>
  </router-link>
</template>
<style lang="scss">
@import "@/_variables.scss";

.saucecard {
  padding-bottom: 1rem;
  text-align: center;
  border-bottom: 3px solid transparent;
  position: relative;

  &.active {
    border-bottom: 3px solid $hsc-brown;
  }

  .photo {
    z-index: 11;
    position: relative;
  }

  .copy {
    position: relative;

    > * {
      position: relative;
    }

    .bg {
      opacity: 0;
      content: " ";
      left: 0;
      right: 0;
      bottom: -2rem;
      top: -3rem;
      left: -1rem;
      right: -1rem;
      position: absolute;
      margin: 0;
      padding: 0;
      transition: opacity .3s;
    }
  }

  @media(min-width: 641px) {
    &:hover {
      div.copy {
        color: #ffffff !important;

        div {
          color: #ffffff !important;
        }

        .bg {
          opacity: 1;
        }
      }
    }
  }
}

@media(min-width: 641px) {

  html #hscmenu #menu .saucecard:hover {
    div.copy {
      color: #ffffff !important;

      div {
        color: #ffffff !important;
      }
    }
  }
}

</style>
