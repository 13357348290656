<script>
export default {
  data() {
    return {
      hscData: this.$hscData,
      menu: false,
      background: 'one',
      bgTimer: null
    }
  },
  mounted() {
    this.bgTimer = setInterval(() => {
      this.background = (this.background === 'one' ? 'two' : 'one');
    }, 10000)
  },
  unmounted() {
    clearInterval(this.bgTimer);
  },
  methods: {
    toggle: function () {
      this.menu = !this.menu
    },
    switchBackground: () => {
      // console.log('switchBackground');
    }
  }
}
</script>
<template>
  <section id="hero" v-bind:class="background">
    <div class="logo-container">
      <img class="shadow" src="~@/../public/images/nav-shadow.png" alt="">
      <img class="logo" src="~@/../public/images/homesteadchef_logo_sm.png" alt="Homestead Chef’s Table">
    </div>
    <div class="copy-container">
      <div class="inner">
        <div class="bpt" v-html="hscData.hero.title"></div>
        <p v-html="hscData.hero.desc"></p>
      </div>
    </div>
  </section>
</template>
<style lang="scss">
@import "@/_variables.scss";

</style>
