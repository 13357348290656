<script>
import saucecard from '@/components/saucecard.vue'

export default {
  components: {
    saucecard
  },
  data: function() {
    return {
      hscData: this.$hscData,
      content: this.$hscData.content.exploresauces,
      sauces: this.$hscData.sauces,
    }
  },
  mounted() {
  },
  unmounted() {
  },
  methods: {}
}
</script>
<template>
  <section id="our-sauces">
    <div>
      <header>
        <h2 v-html="content.header[$root.lang]"/>
      </header>
      <div id="our-sauces-grid">
        <ul>
          <li v-for="item in sauces"
              :key="item.slug">
            <saucecard
                :item="item" :id="`${item.slug}-oursauces`" :details="true"/>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
<style lang="scss">
@import "@/_variables.scss";

#our-sauces {
  padding-top: 3rem;
  max-width: 1440px;
  margin: 0 auto;

  h2 {
    margin-bottom: 3rem;
  }

  img {
    image-rendering: -moz-crisp-edges; /* Firefox        */
    image-rendering: -o-crisp-edges; /* Opera          */
    image-rendering: -webkit-crisp-edges; /* Safari         */
    image-rendering: optimize-contrast; /* CSS3 Proposed  */
    -ms-interpolation-mode: nearest-neighbor; /* IE8+           */

  }

  a {
    //font-size: clamp(1.2rem, 2vw, 2rem);
    text-decoration: none;
    text-align: center;
    color: $hsc-brown;

    img {
      display: block;
      margin-bottom: 1em;
    }

  }

  #our-sauces-grid {
    padding: 0 .4rem;
    display: flex;
    align-content: center;
    justify-content: space-around;

    ul {
      display: flex;
      flex: 1;
      width: auto;
      margin: 0 auto;
      align-items: flex-start;
      justify-content: space-around;
      flex-wrap: wrap;

      li {
        flex: 0 20%;
        vertical-align: top;
        align-items: flex-start;

        img {
          //width: 10rem;
          //width: clamp(173px, 16vw, 12rem);
          width: auto;
          height: clamp(173px, 16vw, 12rem);
          margin: 0 auto 1rem;
          margin: 0 auto 1rem;
          @media(max-width: 640px) {
            margin-left: 1rem;
          }
        }

        div.copy div {
          font-size: 1.2rem;
          br {
            display: none;}
        }

        small {
          font-size: 1rem;
          margin-bottom: 1.3rem;
          display: block;
        }

        p {
          max-width: 90%;
          font-size: 0.95rem;
          margin: 0 auto;
        }

        @media(max-width: 960px) {
          flex: 0 35%;
          text-align: center;
          margin-bottom: 4rem;
          div.copy div {
            font-size: 1.8rem;
            min-height: auto;
            max-width: 15rem;
            text-align: center;
            margin: 0 auto;
          }
        }
        @media(max-width: 640px) {
          a {
            display: flex;
          }
          p {
            max-width: none;
          }
          div.copy {
            margin-left: 2rem;
            margin-right: 1rem;
          }
          div.copy div, div {
            margin: 0;
            text-align: left;
          }
          flex: 0 100%;
          text-align: center;
          img {
            //width: 30vw;
          }
          margin-bottom: 3rem;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .saucecard {
    .copy {
      min-height:9rem;
    }
  }
}

</style>
